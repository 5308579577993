import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { DesignSystemProvider } from '@eversity/ui/design-system';
import { IntlProvider } from '@eversity/ui/utils';

import AppRouter from '../AppRouter';
import { queryClient } from '../client';
import { LocaleProvider } from '../generic/intl/locale-provider/LocaleProvider';
import { AuthProvider } from './auth-context';

const AppProviders = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <LocaleProvider>
        <IntlProvider>
          <DesignSystemProvider>
            <AuthProvider>
              <AppRouter />
            </AuthProvider>
          </DesignSystemProvider>
        </IntlProvider>
      </LocaleProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

AppProviders.propTypes = {};

export default AppProviders;
