import { Fragment, lazy, Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import qs from 'query-string';

import { SentryErrorBoundary } from '@eversity/ui/utils';

import { hide as hideSplashScreen } from '../services/splash';
import { useAuth } from './context/auth-context';
import LoadingScreen from './generic/loading/loading-screen/LoadingScreen';
import routes from './routes';
import ErrorView from './views/error/ErrorView';

const AuthenticatedApp = lazy(() => import('./AuthenticatedAppRouter'));
const LoginView = lazy(() => import('./views/login/LoginView'));

const App = () => {
  const { user, isLoading } = useAuth();

  useEffect(() => {
    hideSplashScreen();
  }, []);

  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Fragment>
        {/**
         * Render the login view when on /login.
         * For all other routes, render the AuthenticatedApp if the user is loaded.
         * If the user is not loaded but loading, render a LoadingScreen.
         * If the user is not loaded and not loading, redirect to /login.
         */}
        <Route
          path={routes.LOGIN.ROOT}
          element={
            <SentryErrorBoundary fallback={<ErrorView />}>
              <LoginView />
            </SentryErrorBoundary>
          }
          errorElement={<ErrorView />}
        />

        <Route
          path="*"
          errorElement={<ErrorView />}
          element={
            user?.roles ? (
              <AuthenticatedApp />
            ) : (
              <Fragment>
                {isLoading && <LoadingScreen />}

                {!isLoading && (
                  <Navigate
                    to={{
                      pathname: routes.LOGIN.ROOT,
                      search: qs.stringify({
                        origin: `${window.location.pathname}${
                          window.location.search || ''
                        }`,
                      }),
                    }}
                  />
                )}
              </Fragment>
            )
          }
        />
      </Fragment>,
    ),
  );

  return (
    <Suspense fallback={<LoadingScreen />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
