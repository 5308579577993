import { type ComponentProps, memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { type FallbackRender } from '@sentry/react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { type ResponseError } from 'superagent';

import { InternalServerError, NotFoundError } from '@eversity/ui/domain';
import { isErrorWithStatus } from '@eversity/utils/misc';

import routes from '../../routes';

export type ErrorViewProps = Partial<
  Omit<ComponentProps<FallbackRender>, 'error'>
> & {
  error?: ComponentProps<FallbackRender>['error'] | ResponseError;
};

export const ErrorViewBase = ({
  error = null,
  resetError = noop,
}: ErrorViewProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onReturnToHome = useCallback(
    () => navigate(routes.HOME.ROOT, { replace: true }),
    [navigate],
  );

  useUpdateEffect(() => {
    resetError();
  }, [location.pathname, resetError]);

  const renderError = () => {
    if (!isErrorWithStatus(error)) {
      return <NotFoundError onReturnToHome={onReturnToHome} />;
    }

    switch (error.status) {
      case 500:
        return <InternalServerError />;
      case 401:
        window.location.href = routes.LOGIN.ROOT;
        return null;
      default:
        return <NotFoundError onReturnToHome={onReturnToHome} />;
    }
  };

  return renderError();
};

ErrorViewBase.displayName = 'ErrorView';

ErrorViewBase.propTypes = {
  // Error caught by boundary.
  error: PropTypes.shape({
    status: PropTypes.number,
  }),
  resetError: PropTypes.func,
};

export default memo(ErrorViewBase);
