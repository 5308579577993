import { components, type MenuListProps } from 'react-select';

const CustomMenuList = ({
  children,
  selectProps,
  innerRef,
  ...rest
}: MenuListProps) => {
  return (
    <components.MenuList
      {...rest}
      selectProps={selectProps}
      innerRef={innerRef}
    >
      {children}
    </components.MenuList>
  );
};

export default CustomMenuList;
