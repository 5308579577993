import { last } from 'lodash';

import {
  ASSIGNMENT_CORRECTION_KIND,
  SUBMISSION_STATUSES,
} from '@eversity/domain/constants';
import {
  type Deposit,
  type SubmissionComment,
  type SubmissionEvaluation,
} from '@eversity/types/domain';

/**
 * Get status of a submission based on its data.
 * Last deposit is used to determine the status.
 *
 * @param submission - Assignment submission.
 * @param deposits - Submission deposits.
 * @returns Assignment submission status.
 */
export const getSubmissionStatus = (deposits: Deposit[]) => {
  const { correction, submissionDate } = last(deposits);

  if (correction) {
    const { correctionKind, date } = correction;

    if (correctionKind === ASSIGNMENT_CORRECTION_KIND.COMMENT) {
      return SUBMISSION_STATUSES.COMMENTED;
    }

    if (date) {
      return SUBMISSION_STATUSES.CORRECTED;
    }

    if (correctionKind !== ASSIGNMENT_CORRECTION_KIND.MCQ) {
      const { corrector } = correction as
        | SubmissionComment
        | SubmissionEvaluation;

      if (corrector) {
        return SUBMISSION_STATUSES.CORRECTION_IN_PROGRESS;
      }

      return SUBMISSION_STATUSES.SUBMITTED;
    }
  }

  if (submissionDate) {
    return SUBMISSION_STATUSES.SUBMITTED;
  }

  return SUBMISSION_STATUSES.IN_PROGRESS;
};
