import { type VersionObjectBase } from '@eversity/types/domain';

/**
 * Compare version numbers for versions sorting.
 *
 * @param versionA - First version to compare.
 * @param versionB - Second version to compare.
 * @returns -1 if first version if lesser than second, 1 otherwise.
 */
export const compareVersionsDesc = (
  versionA: VersionObjectBase,
  versionB: VersionObjectBase,
) => {
  const { versionMajor: aMajor, versionMinor: aMinor } = versionA;

  const { versionMajor: bMajor, versionMinor: bMinor } = versionB;

  if (aMajor === bMajor) {
    return aMinor < bMinor ? -1 : 1;
  }

  return aMajor < bMajor ? -1 : 1;
};
