import { type AssignmentCorrectionFee } from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const ASSIGNMENT_CORRECTION_FEES_API_URI =
  '/api/v1/school/assignment-correction-fees';

export class AssignmentCorrectionFeesRepository extends HttpRepository {
  /**
   * Get all assignment correction fees.
   *
   * @returns Assignment correction fees.
   */
  async getAssignmentCorrectionFees(): Promise<AssignmentCorrectionFee[]> {
    const { body } = await this.http.get(ASSIGNMENT_CORRECTION_FEES_API_URI);

    return body;
  }
}
