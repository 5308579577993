import { useMemo } from 'react';
import { createFilter, type GroupBase } from 'react-select';
import AsyncSelect, { type AsyncProps } from 'react-select/async';
import { useTheme } from '@emotion/react';
import { noop } from 'lodash';

import { type SelectOption } from '@eversity/types/web';
import { useMemoizedBundle } from '@eversity/ui/utils';

import ClearIndicator from '../select/components/ClearIndicator';
import DropdownIndicator from '../select/components/DropdownIndicator';
import MultiValueRemove from '../select/components/MultiValueRemove';
import { SELECT_SIZES } from '../select/constants';
import { makeCustomStyles, makeCustomTheme } from '../select/Select.styles';
import CustomOption from './components/custom-option/CustomOption';
import CustomMenuList from './components/CustomMenuList';

type AsyncSelectBaseProps<Option, IsMulti extends boolean = false> = AsyncProps<
  Option,
  IsMulti,
  GroupBase<Option>
> & {
  onInputChange?: (value: string) => void;
};

export const AsyncSelectCheckboxes = <
  Option extends SelectOption<string>,
  IsMulti extends boolean = false,
>({
  value,
  onChange = noop,
  placeholder = null,
  isLoading,
  defaultOptions = [],
  loadOptions,
  onInputChange = noop,
  isMulti,
  closeMenuOnSelect = false,
  hideSelectedOptions = false,
  onMenuScrollToBottom,
  menuPlacement = 'bottom',
  instanceId = null,
}: AsyncSelectBaseProps<Option, IsMulti>) => {
  const theme = useTheme();

  const customTheme = useMemo(
    () =>
      makeCustomTheme({
        theme,
        hasError: false,
        hasWarning: false,
        size: SELECT_SIZES.MEDIUM,
      }),
    [theme],
  );

  const customStylesProps = useMemoizedBundle({
    theme,
    size: SELECT_SIZES.MEDIUM,
    hasError: false,
    hasWarning: false,
    isInLayer: false,
    layerZIndex: undefined,
  });

  const customStyles = useMemo(
    () => makeCustomStyles(customStylesProps),
    [customStylesProps],
  );

  return (
    <AsyncSelect<Option, IsMulti, GroupBase<Option>>
      instanceId={instanceId}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isLoading={isLoading}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onInputChange={onInputChange}
      components={{
        MenuList: CustomMenuList,
        IndicatorSeparator: () => null,
        Option: CustomOption,
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
      }}
      isSearchable
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      filterOption={createFilter<Option>({
        stringify: ({ data: option }) =>
          `${option.stringifiedLabel || option.label || ''} ${option.value}`,
      })}
      onMenuScrollToBottom={onMenuScrollToBottom}
      cacheOptions
      theme={customTheme}
      styles={customStyles}
      menuPlacement={menuPlacement}
    />
  );
};
