/**
 * @description This method verifies that the date in parameter is later than the present time.
 * @param time
 * @returns boolean
 */
export const isDateInFuture = (time: Date) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

/**
 * @description This method converts a string date to a cron format (ex: "30 5 9 5 2").
 *
 * @param date - Date
 * @returns string
 * ! This function does not handle frequency.
 */
export const convertDateToCronFormat = (date: Date) => {
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const days = date.getDate();
  const months = date.getMonth() + 1;

  return `${minutes} ${hours} ${days} ${months} *`;
};
