import { useQuery } from '@tanstack/react-query';

import { UsersRepository } from '@eversity/domain/data/repositories';
import { type EmployeeViewFull } from '@eversity/types/domain';
import { type UseQueryParams } from '@eversity/types/web';

import http from '../../../services/http/api';

const usersRepository = new UsersRepository(http);

export const useUser = (useQueryParams?: UseQueryParams<EmployeeViewFull>) =>
  useQuery({
    queryKey: ['user'],
    queryFn: () => usersRepository.getCurrentUser(),
    ...useQueryParams,
  });
