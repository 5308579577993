/**
 * Compute the average of a list of weighted values.
 * All values are on the same scale.
 *
 * @param values - Values with their weight.
 * @param values[].value - Grade.
 * @param values[].weight - Coefficient.
 * @returns Weighted average, or null if no values.
 */
export function getWeightedAverage(
  values: { value: number; weight: number }[],
): number | null {
  const totalWeight = values.reduce((total, { weight }) => total + weight, 0);

  const weightedAverage =
    values.reduce(
      (totalValue, { value, weight }) => totalValue + value * weight,
      0,
    ) / totalWeight;

  return Number.isNaN(weightedAverage) ? null : weightedAverage;
}

export const roundToTwoDecimals = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export const isStringNumeric = (value: string) =>
  !Number.isNaN(parseFloat(value));
